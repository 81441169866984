import { useMutation } from '@tanstack/react-query';
import {
  postApiV2AuthLogin,
  Params as LoginParams,
} from '@hungryroot/rest/api/v2/auth/login/post';
import { StatusCode } from '@hungryroot/rest';

export { type LoginParams };

export function useLogin() {
  return useMutation({
    mutationFn: async (params: LoginParams) => {
      const resp = await postApiV2AuthLogin(params);

      if (resp.status !== StatusCode.SuccessAccepted) {
        throw new Error('Bad Login');
      }
    },
  });
}
