import React, { useEffect, useState } from 'react';
import { LoginForm as PageLoginForm } from '@hungryroot/page-login';
import { islandRoot } from '../IslandRoot';

import styles from './LoginFormIsland.module.css';

function LoginForm() {
  const [className, setClassName] = useState(styles.loading);

  // don't show the form until the client has loaded
  useEffect(() => {
    setClassName('');
  }, []);

  return (
    <PageLoginForm
      className={className}
      onLoginSuccess={({ next }) => {
        window.location.href = next;
      }}
    />
  );
}

export const LoginFormIsland = islandRoot(LoginForm);
