import { useFeatureFlag } from '@hungryroot/feature-flags';

type Props<T> = {
  recaptcha: T;
};

export function useWebLoginChallenge<T>({ recaptcha }: Props<T>) {
  const flag = useFeatureFlag('web.login_challenge');

  switch (flag) {
    case 'recaptcha': {
      return recaptcha;
    }
    case undefined:
      return undefined;
    case false:
      return null;
  }
}
