import React, { useEffect, useState } from 'react';
import { produce } from 'immer';
import {
  ButtonSizeVariant,
  ButtonStyleVariant,
  ButtonProminenceVariant,
  Button,
  TextInput,
  Paragraph,
  ParagraphVariant,
} from '@hungryroot/ramen';
import { type LoginParams, useLogin } from '@hungryroot/customer';
import { generateToken, loadRecaptcha } from '../../captcha/recaptcha';
import { useWebLoginChallenge } from '@hungryroot/feature-flag-web-login-challenge';
import styles from './LoginForm.module.css';

interface Props {
  className?: string;
  onLoginSuccess: (data: { next: string }) => void;
}

function getNextRoute(search: URLSearchParams) {
  const next = search.get('next');
  if (next === null) {
    return '/myhungryroot';
  }

  const nextUrl = new URL(next, window.origin);
  const { searchParams: nextSearchParams } = nextUrl;
  search.forEach((value, name) => {
    if (name === 'next') {
      return;
    }
    nextSearchParams.append(name, value);
  });

  return `${nextUrl.pathname}${nextUrl.search}`;
}

const LOGIN_ERROR_MESSAGE = 'Invalid email or password.';

export function LoginForm({ className, onLoginSuccess }: Props) {
  const [captchaLoaded, setCaptchaLoaded] = useState(false);
  const [credentials, setCredentials] = useState<LoginParams>({
    email: '',
    password: '',
  });
  const captcha = useWebLoginChallenge({
    recaptcha: {
      load: async () => {
        loadRecaptcha();
      },
      generateToken: async () => {
        return await generateToken({ action: 'login' });
      },
      catchaMessage: (
        <Paragraph
          className={styles.recaptcha}
          variant={ParagraphVariant.Caption}
        >
          This site is protected by reCAPTCHA and the Google{' '}
          <a
            className={styles['recaptcha-link']}
            target="_BLANK"
            href="https://policies.google.com/privacy"
          >
            Privacy Policy
          </a>{' '}
          and{' '}
          <a
            className={styles['recaptcha-link']}
            target="_BLANK"
            href="https://policies.google.com/terms"
          >
            Terms of Service
          </a>{' '}
          apply.
        </Paragraph>
      ),
    },
  });

  useEffect(() => {
    if (captcha === undefined || captcha === null) {
      return;
    }

    captcha.load().then(() => {
      setCaptchaLoaded(true);
    });
  }, [captcha]);

  const [error, setError] = useState<string | undefined>();
  const login = useLogin();

  // if captcha === null, then it is turned off
  // if captcha is not null, it will be loaded in the useEffect above
  // we have to wait for it
  const captchaReady = captchaLoaded === true || captcha === null;
  if (captcha === undefined || captchaReady === false) {
    return;
  }

  return (
    <form
      className={`${styles.form} ${className}`}
      onSubmit={async (e) => {
        e.preventDefault();
        const search = new URLSearchParams(window.location.search);

        login.mutate(
          {
            captcha_token: await captcha?.generateToken(),
            ...credentials,
          },
          {
            onSuccess() {
              onLoginSuccess({
                next: getNextRoute(search),
              });
            },
            onError() {
              setError(LOGIN_ERROR_MESSAGE);
            },
          }
        );
      }}
    >
      <TextInput
        className={styles.input}
        /* Text input only shows error ui if errorMessage is not undefined */
        errorMessage={error === undefined ? error : ''}
        aria-errormessage={error}
        type="email"
        label="Email"
        onChange={(value) => {
          setCredentials(
            produce((draft) => {
              draft.email = value;
            })
          );
        }}
      />
      <div className={styles.password}>
        <TextInput
          className={styles.input}
          /* Text input only shows error ui if errorMessage is not undefined */
          errorMessage={error === undefined ? error : ''}
          type="password"
          label="Password"
          onChange={(value) => {
            setCredentials(
              produce((draft) => {
                draft.password = value;
              })
            );
          }}
        />
        {error !== undefined && (
          <Paragraph
            className={styles.error}
            variant={ParagraphVariant.Caption}
          >
            {error}
          </Paragraph>
        )}
        {captcha?.catchaMessage}
      </div>
      <Button
        size={ButtonSizeVariant.Medium}
        style={ButtonStyleVariant.Default}
        prominence={ButtonProminenceVariant.Primary}
        textPrimary="Log in"
      />
    </form>
  );
}
