import { AUTHORIZATION_HEADER } from '../../../../../src/authorization';
import { send } from '../../../../../src/network';
import { Login } from '../../../../../src/types/Login';

export type Params = Omit<Login, 'id'>;

export async function postApiV2AuthLogin(params: Params) {
  return await send<Login>({
    path: '/api/v2/auth/login/',
    method: 'POST',
    body: params,
    headers: new Headers({
      AUTHORIZATION: AUTHORIZATION_HEADER,
      'Content-Type': 'application/json',
    }),
  });
}
